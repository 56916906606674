@use '~@angular/material' as mat;

@import 'base/typography';

@import 'components/form-field';
@import 'components/button';
@import 'components/snackbar';

@import 'layout/layout-utils';

@import 'themes/theme';

@import 'utilities/media-queries';
@import 'utilities/common';
@import 'utilities/flex';
@import 'utilities/responsive';

@include mat.all-component-themes($app-theme);

@include mat.core($app-typography);
@include app-custom-typography();

@include media-query(md){
  @include mat.core($app-typography-md);
  @include app-custom-typography-md();
}

@include app-form-fields();
@include app-button();
@include app-mat-snackbar();

html{
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: $font-family-opensans, sans-serif;
}

