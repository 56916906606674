@import "utilities/media-queries";
@import "layout/layout-utils";

$directions: (
  row: row,
  column: column,
  row-reverse: row-reverse,
  column-reverse: column-reverse
);

$wrap: (
  nowrap: nowrap,
  wrap: wrap,
  wrap-reverse: wrap-reverse
);

$justify-content: (
  start: flex-start,
  end: flex-end,
  center: center,
  space-between: space-between,
  space-around: space-around,
  space-evenly: space-evenly
);

$align-items: (
  stretch: stretch,
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline
);

$align-self: (
  auto: auto,
  stretch: stretch,
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline
);

$gaps_size: 2px, 4px, 8px, 12px, 16px, 24px, 32px, 40px, 56px, 64px, 79px, 92px, 100px, 128px, 183px;

@mixin flex-direction($direction: row) {
  @if map-has-key($directions, $direction) {
    flex-direction: map-get($directions, $direction);
  } @else {
    @warn 'Invalid direction: #{$direction}.';
  }
}

@mixin flex-wrap($value: nowrap) {
  @if map-has-key($wrap, $value) {
    flex-wrap: map-get($wrap, $value);
  } @else {
    @warn 'Invalid wrap: #{$value}.';
  }
}

@mixin justify-content($value: start, $important: false) {
  @if map-has-key($justify-content, $value) {
    @if ($important) {
      justify-content: map-get($justify-content, $value) !important;
    } @else {
      justify-content: map-get($justify-content, $value);
    }
  } @else {
    @warn 'Invalid justify-content: #{$value}.';
  }
}

@mixin align-content($value: stretch, $important: false) {
  @if map-has-key($align-items, $value) {
    @if ($important) {
      align-content: map-get($align-items, $value) !important;
    } @else {
      align-content: map-get($align-items, $value);
    }
  } @else {
    @warn 'Invalid align-content: #{$value}.';
  }
}

@mixin align-items($value: stretch, $important: false) {
  @if map-has-key($align-items, $value) {
    align-items: map-get($align-items, $value);
    @if ($important) {
      align-items: map-get($align-items, $value) !important;
    } @else {
      align-items: map-get($align-items, $value);
    }
  } @else {
    @warn 'Invalid align-items: #{$value}.';
  }
}

@mixin align-self($value: stretch) {
  @if map-has-key($align-self, $value) {
    align-items: map-get($align-self, $value);
  } @else {
    @warn 'Invalid align-self: #{$value}.';
  }
}

@mixin place-content($value: stretch, $important: false) {
  @include align-items($value, $important);
  @include align-content($value, $important);
}

@mixin display-flex($direction, $align-hor: start, $align-vert: stretch) {
  display: flex;
  @include flex-direction($direction);
  @include justify-content($align-hor);
  @include place-content($align-vert);
}

@mixin row-gap($value) {
  row-gap: $value;
}

@mixin column-gap($value) {
  column-gap: $value;
}

@mixin gap($value) {
  @include row-gap($value);
  @include column-gap($value);
}

@mixin flex-order($value) {
  order: $value;
}

@mixin flex-grow($value) {
  flex-grow: $value;
}

@mixin flex-shrink($value) {
  flex-shrink: $value;
}

@mixin flex-layout($direction) {
  display: flex;
  @include flex-direction($direction);
  @include justify-content();
  @include place-content();
}

@mixin flex-layout-classes($responsive) {
  @each $direction-key, $_ in $directions {
    .layout__#{$direction-key} {
      @if ($responsive) {
        @include responsive-classes() {
          @include flex-layout($direction-key)
        }
      } @else {
        @include flex-layout($direction-key)
      }
    }
  }
}


@mixin flex-align-classes($responsive) {
  @each $justify-content-key, $_ in $justify-content {
    .align__#{$justify-content-key} {
      @if ($responsive) {
        @include responsive-classes() {
          @include justify-content($justify-content-key, true);
          @include place-content();
        }
        @each $align-items-key, $_ in $align-items {
          &__#{$align-items-key} {
            @include responsive-classes() {
              @include justify-content($justify-content-key, true);
              @include place-content($align-items-key, true);
            }
          }
        }
      } @else {
        @include justify-content($justify-content-key, true);
        @include place-content();

        @each $align-items-key, $_ in $align-items {
          &__#{$align-items-key} {
            @include justify-content($justify-content-key, true);
            @include place-content($align-items-key, true);
          }
        }
      }
    }
  }
}

@mixin gap-classes($responsive) {
  @each $gap in $gaps_size {
    .flex-gap-#{$gap} {
      @if ($responsive) {
        @include responsive-classes() {
          @include gap($gap);
        }
      } @else {
        @include gap($gap);
      }
    }
  }
}

@mixin greater-than-classes() {
  @each $breakpoint_key, $breakpoint_value in $breakpoints {
    &--gt-#{$breakpoint_key} {
      @if ($breakpoint_key != xl) {
        @include media-query(nextKey($breakpoint_key)) {
          @content
        }
      }
    }
  }
}

@mixin less-than-classes() {
  @each $breakpoint_key, $breakpoint_value in $breakpoints {
    &--lt-#{$breakpoint_key} {
      @if ($breakpoint_key != xs) {
        @include media-query-max-width($breakpoint_key) {
          @content
        }
      }
    }
  }
}

@mixin breakpoints-classes() {
  @each $breakpoint_key, $breakpoint_value in $breakpoints {
    &--#{$breakpoint_key} {
      @if ($breakpoint_key == xs) {
        @include media-query-max-width(sm) {
          @content
        }
      } @else if ($breakpoint_key == xl) {
        @include media-query(xl) {
          @content
        }
      } @else {
        @include media-query($breakpoint_key) {
          @include media-query-max-width(nextKey($breakpoint_key)) {
            @content
          }
        }
      }
    }
  }
}

@mixin responsive-classes() {
  @include greater-than-classes() {
    @content
  }
  @include less-than-classes() {
    @content
  }
  @include breakpoints-classes() {
    @content
  }
}

// non-responsive classes
@include flex-layout-classes(false);
@include flex-align-classes(false);
@include gap-classes(false);

// responsive classes
@include flex-layout-classes(true);
@include flex-align-classes(true);
@include gap-classes(true);
