@import '../utilities/media-queries';

:root {
  --space-xs: 2px;
  --space-s: 4px;
  --space-m: 8px;
  --space-l: 16px;
  --space-xl: 32px;
  --space-xxl: 64px;

  @include media-query(md) {
    --space-xs: 4px;
    --space-s: 8px;
    --space-m: 16px;
    --space-l: 32px;
    --space-xl: 64px;
    --space-xxl: 128px;
  }
}

$spaces-sizes: (
  xs: var(--space-xs),
  s: var(--space-s),
  m: var(--space-m),
  l: var(--space-l),
  xl: var(--space-xl),
  xxl: var(--space-xxl)
);

@mixin padding($size) {
  padding: $size;
}

@mixin padding-top($size) {
  padding-top: $size;
}

@mixin padding-bottom($size) {
  padding-bottom: $size;
}

@mixin padding-left($size) {
  padding-left: $size;
}

@mixin padding-right($size) {
  padding-right: $size;
}

@mixin padding-horizontal($size) {
  padding-right: $size;
  padding-left: $size;
}

@mixin margin($size) {
  margin: $size;
}

@mixin margin-top($size) {
  margin-top: $size;
}

@mixin margin-bottom($size) {
  margin-bottom: $size !important;
}

@mixin margin-left($size) {
  margin-left: $size;
}

@mixin margin-right($size) {
  margin-right: $size;
}

@mixin margin-vertical($size) {
  margin-top: $size;
  margin-bottom: $size;
}

.padding-xs {
  @include padding(map_get($spaces-sizes, xs));
}

.padding-s {
  @include padding(map_get($spaces-sizes, s));
}

.padding-m {
  @include padding(map_get($spaces-sizes, m));
}

.padding-l {
  @include padding(map_get($spaces-sizes, l));
}

.padding-xl {
  @include padding(map_get($spaces-sizes, xl));
}

.padding-xxl {
  @include padding(map_get($spaces-sizes, xxl));
}

.padding-top-xs {
  @include padding-top(map_get($spaces-sizes, xs));
}

.padding-top-s {
  @include padding-top(map_get($spaces-sizes, s));
}

.padding-top-m {
  @include padding-top(map_get($spaces-sizes, m));
}

.padding-top-l {
  @include padding-top(map_get($spaces-sizes, l));
}

.padding-top-xl {
  @include padding-top(map_get($spaces-sizes, xl));
}

.padding-top-xxl {
  @include padding-top(map_get($spaces-sizes, xxl));
}

.padding-top-xs {
  @include padding-bottom(map_get($spaces-sizes, xs));
}

.padding-bottom-s {
  @include padding-bottom(map_get($spaces-sizes, s));
}

.padding-bottom-m {
  @include padding-bottom(map_get($spaces-sizes, m));
}

.padding-bottom-l {
  @include padding-bottom(map_get($spaces-sizes, l));
}

.padding-bottom-xl {
  @include padding-bottom(map_get($spaces-sizes, xl));
}

.padding-bottom-xxl {
  @include padding-bottom(map_get($spaces-sizes, xxl));
}

.padding-left-xs {
  @include padding-left(map_get($spaces-sizes, xs));
}

.padding-left-s {
  @include padding-left(map_get($spaces-sizes, s));
}

.padding-left-m {
  @include padding-left(map_get($spaces-sizes, m));
}

.padding-left-l {
  @include padding-left(map_get($spaces-sizes, l));
}

.padding-left-xl {
  @include padding-left(map_get($spaces-sizes, xl));
}

.padding-left-xxl {
  @include padding-left(map_get($spaces-sizes, xxl));
}

.padding-right-xs {
  @include padding-right(map_get($spaces-sizes, xs));
}

.padding-right-s {
  @include padding-right(map_get($spaces-sizes, s));
}

.padding-right-m {
  @include padding-right(map_get($spaces-sizes, m));
}

.padding-right-l {
  @include padding-right(map_get($spaces-sizes, l));
}

.padding-right-xl {
  @include padding-right(map_get($spaces-sizes, xl));
}

.padding-right-xxl {
  @include padding-right(map_get($spaces-sizes, xxl));
}

.margin-xs {
  @include margin(map_get($spaces-sizes, xs));
}

.margin-s {
  @include margin(map_get($spaces-sizes, s));
}

.margin-m {
  @include margin(map_get($spaces-sizes, m));
}

.margin-l {
  @include margin(map_get($spaces-sizes, l));
}

.margin-xl {
  @include margin(map_get($spaces-sizes, xl));
}

.margin-xxl {
  @include margin(map_get($spaces-sizes, xxl));
}

.margin-top-xs {
  @include margin-top(map_get($spaces-sizes, xs));
}

.margin-top-s {
  @include margin-top(map_get($spaces-sizes, s));
}

.margin-top-m {
  @include margin-top(map_get($spaces-sizes, m));
}

.margin-top-l {
  @include margin-top(map_get($spaces-sizes, l));
}

.margin-top-xl {
  @include margin-top(map_get($spaces-sizes, xl));
}

.margin-top-xxl {
  @include margin-top(map_get($spaces-sizes, xxl));
}

.margin-top-xs {
  @include margin-bottom(map_get($spaces-sizes, xs));
}

.margin-bottom-s {
  @include margin-bottom(map_get($spaces-sizes, s));
}

.margin-bottom-m {
  @include margin-bottom(map_get($spaces-sizes, m));
}

.margin-bottom-l {
  @include margin-bottom(map_get($spaces-sizes, l));
}

.margin-bottom-xl {
  @include margin-bottom(map_get($spaces-sizes, xl));
}

.margin-bottom-xxl {
  @include margin-bottom(map_get($spaces-sizes, xxl));
}

.margin-left-xs {
  @include margin-left(map_get($spaces-sizes, xs));
}

.margin-left-s {
  @include margin-left(map_get($spaces-sizes, s));
}

.margin-left-m {
  @include margin-left(map_get($spaces-sizes, m));
}

.margin-left-l {
  @include margin-left(map_get($spaces-sizes, l));
}

.margin-left-xl {
  @include margin-left(map_get($spaces-sizes, xl));
}

.margin-left-xxl {
  @include margin-left(map_get($spaces-sizes, xxl));
}

.margin-right-xs {
  @include margin-right(map_get($spaces-sizes, xs));
}

.margin-right-s {
  @include margin-right(map_get($spaces-sizes, s));
}

.margin-right-m {
  @include margin-right(map_get($spaces-sizes, m));
}

.margin-right-l {
  @include margin-right(map_get($spaces-sizes, l));
}

.margin-right-xl {
  @include margin-right(map_get($spaces-sizes, xl));
}

.margin-right-xxl {
  @include margin-right(map_get($spaces-sizes, xxl));
}

.margin-vertical-s{
  @include margin-vertical(map_get($spaces-sizes, s));
}

.margin-vertical-l{
  @include margin-vertical(map_get($spaces-sizes, l));
}
