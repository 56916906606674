@import 'themes/theme';

@mixin placeholder(){
  ::placeholder {
    color: $gray;
  }

  :-moz-placeholder {
    color: $gray;
  }

  ::-moz-placeholder {
    color: $gray;
  }

  :-ms-input-placeholder {
    color: $gray;
  }
}

@mixin app-form-fields() {

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $gray!important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $gray;
  }

  .mat-form-field:focus-within label {
    color: $accent-color !important;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
    background-color: $light-primary-text !important;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent{
    color: $accent-color;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: $light-primary-text !important;
  }

  .mat-error{
    color: $light-primary-text;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline{
    color: $gray;
  }

  .mat-form-field-appearance-outline:focus-within .mat-form-field-outline{
    color: $light-primary-text !important;
  }

}
