@use '~@angular/material' as mat;

@import 'base/font';

$font-family-opensans: 'OpenSans', sans-serif;
$font-family-nunitosans: 'NunitoSans', sans-serif;

$app-typography: mat.define-typography-config(
  $display-1: mat.define-typography-level(
      32px,
      45px,
      700,
      $font-family-nunitosans,
    $letter-spacing: 0.06em
  ),
  $headline: mat.define-typography-level(
      24px,
      34px,
      600,
      $font-family-nunitosans,
    $letter-spacing: 0.06em
  ),
  $title: mat.define-typography-level(
      20px,
      28px,
      600,
      $font-family-nunitosans,
    $letter-spacing: 0.04em
  ),
  $subheading-2: mat.define-typography-level(
      16px,
      22px,
      500,
      $font-family-nunitosans,
    $letter-spacing: 0.03em
  ),
  $subheading-1: mat.define-typography-level(
      14px,
      20px,
      400,
      $font-family-nunitosans,
    $letter-spacing: 0.02em
  ),
  $body-2: mat.define-typography-level(
      16px,
      21px,
      400,
      $font-family-opensans,
    $letter-spacing: 0.02em
  ),
  $body-1: mat.define-typography-level(
      14px,
      19px,
      400,
      $font-family-opensans,
    $letter-spacing: 0.04em
  ),
  $button: mat.define-typography-level(
      14px,
      17px,
      500,
      $font-family-opensans,
    $letter-spacing: 0.16em
  ),
  $caption: mat.define-typography-level(
      12px,
      14px,
      500,
      $font-family-opensans,
    $letter-spacing: 0.06em
  )
);

$app-typography-md: mat.define-typography-config(
  $display-1: mat.define-typography-level(
      32px,
      45px,
      700,
      $font-family-nunitosans,
    $letter-spacing: 0.06em
  ),
  $headline: mat.define-typography-level(
      24px,
      34px,
      600,
      $font-family-nunitosans,
    $letter-spacing: 0.06em
  ),
  $title: mat.define-typography-level(
      20px,
      28px,
      600,
      $font-family-nunitosans,
    $letter-spacing: 0.04em
  ),
  $subheading-2: mat.define-typography-level(
      16px,
      22px,
      500,
      $font-family-nunitosans,
    $letter-spacing: 0.03em
  ),
  $subheading-1: mat.define-typography-level(
      14px,
      20px,
      400,
      $font-family-nunitosans,
    $letter-spacing: 0.02em
  ),
  $body-2: mat.define-typography-level(
      16px,
      21px,
      400,
      $font-family-opensans,
    $letter-spacing: 0.02em
  ),
  $body-1: mat.define-typography-level(
      14px,
      19px,
      400,
      $font-family-opensans,
    $letter-spacing: 0.04em
  ),
  $button: mat.define-typography-level(
      14px,
      17px,
      500,
      $font-family-opensans,
    $letter-spacing: 0.16em
  ),
  $caption: mat.define-typography-level(
      12px,
      14px,
      500,
      $font-family-opensans,
    $letter-spacing: 0.06em
  )
);

@mixin app-custom-typography {
  .nunito-body-2 {
    $font-size: 14px;
    $font-weight: 400;
    $line-height: 20px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.05px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }

  .section-title {
    $font-size: 24px;
    $font-weight: 700;
    $line-height: 34px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.17px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }

  .button-label {
    $font-size: 16px;
    $font-weight: 500;
    $line-height: 23px;
    $font-family: $font-family-opensans;
    letter-spacing: 0.13px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }
}

@mixin app-custom-typography-md {
  .nunito-body-2 {
    $font-size: 16px;
    $font-weight: 400;
    $line-height: 23px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.05px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }

  .section-title {
    $font-size: 56px;
    $font-weight: 700;
    $line-height: 78px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.17px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }

  .card-title-1 {
    $font-size: 25px;
    $font-weight: 600;
    $line-height: 34px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.24px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }

  .card-title-2 {
    $font-size: 14px;
    $font-weight: 600;
    $line-height: 23px;
    $font-family: $font-family-nunitosans;
    letter-spacing: 0.84px;
    @include mat.font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
    );
  }
}
