@use '~@angular/material' as mat;

$mat-primary: (
  50: #e2e3e4,
  100: #b7babc,
  200: #888c90,
  300: #585d63,
  400: #343b41,
  500: #101820,
  600: #0e151c,
  700: #0c1118,
  800: #090e13,
  900: #05080b,
  A100: #5184ff,
  A200: #1e60ff,
  A400: #0044ea,
  A700: #003dd0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$mat-accent: (
  50: #fef7ee,
  100: #fdecd4,
  200: #fbdfb7,
  300: #f9d29a,
  400: #f8c985,
  500: #f7bf6f,
  600: #f6b967,
  700: #f5b15c,
  800: #f3a952,
  900: #f19b40,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffedda,
  A700: #ffe0c1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-warn: (
  50: #fae3e8,
  100: #f2b9c5,
  200: #e98b9f,
  300: #e05d79,
  400: #da3a5c,
  500: #d3173f,
  600: #ce1439,
  700: #c81131,
  800: #c20d29,
  900: #b7071b,
  A100: #ffe2e4,
  A200: #ffafb5,
  A400: #ff7c86,
  A700: #ff636e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// color palettes
$primary: mat.define-palette($mat-primary);
$accent: mat.define-palette($mat-accent);
$warn: mat.define-palette($mat-warn);

$app-theme: mat.define-light-theme($primary, $accent, $warn);

$warn-color: map-get($warn, 500);
$primary-color: map-get($primary, 500);
$accent-color: map-get($accent, 500);

$dark-primary-text: #000000;
$light-primary-text: #ffffff;

$black: #000;

$gray: #3D464E;
$inactive-text: #707070;
$accent-color-darker: #6E532D;
