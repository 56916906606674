@import 'themes/theme';

@mixin app-button() {

  .mat-stroked-button {
    border-radius: 8px !important;
    border-color: $gray !important;
    color: $light-primary-text !important;
    height: 56px;
  }

  .mat-stroked-button:hover {
    border-color: $accent-color-darker !important;
    color: $light-primary-text !important;

    background: url("~assets/images/Background-flipped.png") no-repeat bottom right;
    background-size: contain;
  }
}
