@import 'themes/theme';

@mixin icon-size($size) {
  font-size: $size;
  line-height: $size !important;
  width: $size !important;
  height: $size !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-height-vh {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.accent-color {
  color: $accent-color;
}

.primary-color {
  color: $primary-color;
}

.light-text-color{
  color: $light-primary-text;
}

.no-margin {
  margin: 0 !important;
}

.overflow-auto {
  overflow: auto;
}

.weight-600{
  font-weight: 600!important;
}

.background-black{
  background-color: $black;
}
