@import 'utilities/media-queries';

:root {
  --max-width: 100%;
  --image-height: 265px;

  @include media-query(sm) {
    --max-width: 90%;
    --image-height: 425px;
  }

  @include media-query(md) {
    --max-width: 835px;
    --image-height: 566px;
  }

  @include media-query(lg) {
    --max-width: 1250px;
    --image-height: 845px;
  }

  @include media-query(xl) {
    --max-width: 1660px;
    --image-height: 885px;
  }
}

$max-width: var(--max-width);
$image-height: var(--image-height);

.page {
  margin: auto;
  max-width: $max-width;
}

.carousel {
  height: $image-height;
}
